export const HOME = '/';

export const LOGIN = `/login`;

export const LOGOUT = `/logout`;

export const MAIL_AUTH = `/mail_auth`;
export const MAIL_AUTH_SENT = `/mail_auth_sent`;
export const MAIL_AUTH_VERIFY = `/mail_auth_verify`;
export const MAIL_PRIVATE_VERIFY = `/mail_private_verify`;

export const REGISTS_DIR_FIRST = `/regists`;
export const REGISTS_PROFILE = `/regists/profile`;
export const REGISTS_EDUCATION = `/regists/education`;
export const REGISTS_SKILL = `/regists/skill`;
export const REGISTS_IT_SKILL = `/regists/it_skill`;
export const REGISTS_WISH = `/regists/wish`;
export const REGISTS_FOLLOW = `/regists/follow`;
export const REGISTS_CONFIRM = `/regists/confirm`;
export const REGISTS_FINISH = `/regists/finish`;

export const STUDENT_CARD = `/student_card`;
export const STUDENT_CARD_APPLIED = `/student_card/applied`;

export const REMIND_PASS = `/remindpass`;

export const RESET_PASS = `/resetpass`;

export const EVENT = `/event`;

export const BOX_DIR_FIRST = `/box`;

export const BOX_ANSWERS = `/box/answers`;

export const BOX_AUTH = `/box/auth`;

export const COMPANIES = `/company`;

export const RECRUITING_INFO = `/recruiting_info`;

export const COLUMNS = `/column/categories`;

export const COLUMN_RANKING = `/column/ranking`;

export const COLUMN_RECENTLY = `/column/recently`;

export const COLUMN_SPONSORED = `/column/sponsored`;

export const COLUMN_SPECIAL_INDEX = `/special/index`;

export const QUESTIONS = `/questions`;

export const RECRUITINGS = `/recruiting_info`;

export const REPORTS = `/reports`;

export const COMMUNITY = `/questions`;

export const RULE_CONFIRM = `/rule/confirm`;

export const NEW_GRADES_PROFILE_UPDATE = `/new_grades`;

export const NEW_GRADES_EDIT_WISH_STATUS = `/new_grades/edit_wish_status`;

export const CORPORATE_PRIVACY_POLICY = `/corporate_privacy_policy`;

export const SERVICE_PRIVACY_POLICY = `/service_privacy_policy`;

export const INQUIRY = `/inquiry`;

export const INQUIRY_FOREIGN_UNIV = `/inquiry/foreign_univ`;

export const DISCLAIMER = `/disclaimer`;

export const TERMS = `/terms`;

export const LP_TO_LIIGA_ACCOUNT = `/lp/to_liiga_account`;

export const FOLLOW_COMPANIES = `/mypage/follow_companies`;

export const HOW_TO_USE_ARTICLE = `/archives/162496`;

export const QUIT = `/quit`;

export const QUIT_THANK_TOU = `/thankyou`;

export const PROFILE_BASE = `/mypage/profile`;
export const PROFILE_GENERAL = `/mypage/profile/edit/general`;
export const PROFILE_PR_MESSAGE = `/mypage/profile/edit/pr_message`;
export const PROFILE_WISH = `/mypage/profile/edit/wish`;
export const PROFILE_MENTALITY = `/mypage/profile/edit/mentality`;
export const PROFILE_EDUCATION = `/mypage/profile/edit/education`;
export const PROFILE_INTERN = `/mypage/profile/edit/intern`;
export const PROFILE_ADDITIONAL_BACKGROUND = `/mypage/profile/edit/additional_background`;
export const PROFILE_LANGUAGE = `/mypage/profile/edit/language`;
export const PROFILE_IT_SKILL = `/mypage/profile/edit/it_skill`;
export const PROFILE_FOREIGN_EXPERIENCE = `/mypage/profile/edit/foreign_experience`;
export const PROFILE_OFFER = `/mypage/profile/edit/offer`;
export const PROFILE_OFFER_EDIT = `/mypage/profile/edit/offer?mode=edit`;

export const MYPAGE_SCOUT_SETTING = `/mypage/profile/edit/scout_setting`;
export const MYPAGE_DM_SETTING = `/mypage/profile/edit/dm`;

export const FAQ = `/faq`;

export const TAREKOMI = `/inquiry/tarekomi`;

export const ADVERTISE = `/inquiry/advertise`;

export const QUIT_THANKYOU = '/thankyou';

export const ENGINEER = `/engineer`;

export const ENGINEER_COMPANY = `/engineer/company`;

export const ENGINEER_COMPANY_REPORT = `/engineer/company/reports`;

export const ENGINEER_COLUMN = `/engineer/column`;

export const ENGINEER_RECRUITING_INFO = `/engineer/recruiting_info`;

export const REPORT_ANALYTICS_SEARCH = `/report_analytics/search`;

export const REPORT_ANALYTICS_RESULT = `/report_analytics/result`;

export const LIIGA = `https://liiga.me/`;

export const MOND = `https://mond.how/`;

export const HOWTELEVISION = `https://howtelevision.co.jp/`;

export const URL_TO_ANDROID_APP_WEB =
  'https://play.google.com/store/apps/details?id=com.gaishishukatsu.Gaishishukatsu&hl=ja';

export const URL_TO_iOS_APP_WEB =
  'https://itunes.apple.com/jp/app/%E3%83%88%E3%83%83%E3%83%97%E4%BC%81%E6%A5%AD%E3%81%B8%E5%86%85%E5%AE%9A%E3%82%92%E3%82%81%E3%81%96%E3%81%99%E5%B0%B1%E8%81%B7%E6%B4%BB%E5%8B%95%E3%82%A2%E3%83%97%E3%83%AA-%E5%A4%96%E8%B3%87%E5%B0%B1%E6%B4%BB%E3%83%89%E3%83%83%E3%83%88%E3%82%B3%E3%83%A0/id517232947?mt=8';

export const MYNAVI_FAIR_TEASER = '/jd2019';

export const MYNAVI_FAIR_CAREER_DISCOVERY = '/cd2019';

export const ES = '/mypage/es';

export const PASSED_ES = '/passed_es';

export const ES_NEW = '/mypage/es/new';

export const MYPAGE_REPORTS = '/mypage/reports';

export const MYPAGE_REPORTS_ADD = '/mypage/reports/add';

export const SELECTION_REPORTS = '/selection_reports';

export const EXTERNAL_SERVICES_LINE_RESULT = '/external_services/line/result';

export const EXTERNAL_SERVICES_LINE_SEETTING =
  '/external_services/line/setting';

export const RECRUITMENT_STEP_UPLOAD_FILES =
  '/recruitment_step_upload_files/view';

export const RECRUITMENT_STEP_USER_APPLIES = '/recruitment_step_user_applies';

export const RECRUITMENTS = '/recruitments';

export const ENGINEER_SKILL_CHECK_TOP = '/engineer/skill_check/top';

export const CLIP_REPORTS = '/clip/reports';
export const ENGINEER_DRILL = '/engineer/drill';

export const PMARK = '/pmark';
